<template>
	<div class="card-shadow">
		<div class="pb-2">
			<v-container fluid class="py-0">
				<v-card flat class="custom-grey-border remove-border-radius">
					<v-card-title class="headline grey lighten-4 py-2">
						<h3 class="font-weight-700 custom-headline color-custom-blue" style="margin: 3.5px 0px">
							Permissions
						</h3>
					</v-card-title>
					<v-card-text class="p-6">
						<div class="v-data-table bt-table permission-height theme--light">
							<div class="v-data-table__wrapper">
								<table width="100%">
									<thead>
										<tr>
											<th width="200"></th>
											<th width="100">Full Access</th>
											<th width="100">View</th>
											<th width="100">Create</th>
											<th width="100">Edit</th>
											<th width="100">Delete</th>
											<th width="100">Approve</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row, index) in permissions" :key="index">
											<td class="pt-3">
												<span class="fw-500 mb-0 pl-6">{{ moduleNameFormatted(index) }}</span>
											</td>
											<template v-for="(module, ind) in row">
												<td
													:key="index + ind"
													width="100"
													class="custom-border-top custom-border-right pl-4 pt-3"
												>
													<v-checkbox
														multiple
														:readonly="readonly"
														hide-details
														v-model="formPermissions"
														class="ma-0 pa-0"
														v-on:change="changeFullAccess(module, index)"
														:value="module.id"
													></v-checkbox>
												</td>
											</template>
											<td
												v-if="row.length != 6"
												:colspan="6 - row.length"
												class="custom-border-top pl-4 py-1"
											></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-container>
		</div>
	</div>
</template>
<script>
import { upperFirst, lowerCase } from "lodash";
import { SET_ERROR } from "@/core/services/store/common.module";
import { includes, findIndex, toSafeInteger, cloneDeep } from "lodash";

export default {
	name: "permission",
	title: "Permission",
	props: {
		permissions: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		updatePermissionsList: {
			type: Array,
			default: new Array(),
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formPermissions: [],
		};
	},
	watch: {
		updatePermissionsList: {
			deep: true,
			immediate: true,
			handler(param) {
				this.updatePermissions(param);
			},
		},
	},
	methods: {
		moduleNameFormatted(index) {
			if (index == "checkout") {
				index = "issue";
			}
			if (index == "checkin") {
				index = "receive";
			}
			if (index == "order") {
				index = "record";
			}
			console.log({ index });
			return upperFirst(lowerCase(index));
		},
		changeFullAccess(param, index) {
			const _this = this;
			try {
				if (param.name == "full-access") {
					let append = true;
					if (includes(_this.formPermissions, param.id) === false) {
						append = false;
					}

					for (let i = 0; i < _this.permissions[index].length; i++) {
						if (append) {
							if (includes(_this.formPermissions, _this.permissions[index][i].id) === false) {
								_this.formPermissions.push(_this.permissions[index][i].id);
							}
						} else {
							let removeIndex = findIndex(_this.formPermissions, function (row) {
								return row == _this.permissions[index][i].id;
							});
							if (removeIndex >= 0) {
								_this.formPermissions.splice(removeIndex, 1);
							}
						}
					}
				} else {
					if (includes(_this.formPermissions, param.id) === false) {
						for (let i = 0; i < _this.permissions[index].length; i++) {
							if (_this.permissions[index][i].name == "full-access") {
								let removeIndex = findIndex(_this.formPermissions, function (row) {
									return row == _this.permissions[index][i].id;
								});
								if (removeIndex >= 0) {
									_this.formPermissions.splice(removeIndex, 1);
								}
							}
						}
					} else {
						let currentIndexValues = [];
						for (let i = 0; i < _this.permissions[index].length; i++) {
							if (includes(_this.formPermissions, _this.permissions[index][i].id)) {
								currentIndexValues.push(_this.permissions[index][i].id);
							}
						}
						if (_this.permissions[index].length === currentIndexValues.length + 1) {
							for (let i = 0; i < _this.permissions[index].length; i++) {
								if (includes(_this.formPermissions, _this.permissions[index][i].id) === false) {
									_this.formPermissions.push(_this.permissions[index][i].id);
								}
							}
						}
					}
				}
				_this.$emit("updatePermissionData", _this.formPermissions);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
		checkAllPermissions() {
			const _this = this;
			let result = [];
			for (let index in _this.permissions) {
				for (let i = 0; i < _this.permissions[index].length; i++) {
					if (includes(result, _this.permissions[index][i].id) === false) {
						result.push(toSafeInteger(_this.permissions[index][i].id));
					}
				}
			}
			_this.formPermissions = cloneDeep(result);
		},
		updatePermissions(permissionData) {
			const _this = this;
			let result = [];
			for (let index in _this.permissions) {
				for (let i = 0; i < _this.permissions[index].length; i++) {
					if (includes(permissionData, _this.permissions[index][i].id)) {
						result.push(toSafeInteger(_this.permissions[index][i].id));
					}
				}
			}
			_this.formPermissions = cloneDeep(result);
		},
	},
};
</script>
